import { createGlobalStyle } from 'styled-components';

export const bpSizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
};

export const bp = {
  mobileS: `(min-width: ${bpSizes.mobileS})`,
  mobileM: `(min-width: ${bpSizes.mobileM})`,
  mobileL: `(min-width: ${bpSizes.mobileL})`,
  tablet: `(min-width: ${bpSizes.tablet})`,
  laptop: `(min-width: ${bpSizes.laptop})`,
  laptopL: `(min-width: ${bpSizes.laptopL})`,
  desktop: `(min-width: ${bpSizes.desktop})`,
  desktopL: `(min-width: ${bpSizes.desktop})`
};

export const theme = {
  color: "#91908E",
  colorLight: "#B2B1AF",
  colorMedium: "#797874",
  colorDark: "#2D2D2D",
  colorDark20: "#CDCCC8",
  colorWhite: "#FFFFFF",
  colorWhite60: "#ADADAD",
  bgColor: "#EBEADF",
  bgColorLight: "#f5f4ef",
  borderColor: "#DCDCD9",
  columns: 12,
  ffLight: `GT-America-Light, serif`,
  ffRegular: `GT-America-Regular, serif`,
  gap: "25px",
  gapTablet: "20px",
  gutter: "20px",
  maxWidth: bpSizes.laptopL
};
 
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'GT-America-Light';
    src:  url('/fonts/GT-America-Standard-Light.woff2') format('woff2'),
          url('/fonts/GT-America-Standard-Light.woff') format('woff'),
          url('/fonts/GT-America-Standard-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'GT-America-Regular';
    src:  url('/fonts/GT-America-Standard-Regular.woff2') format('woff2'),
          url('/fonts/GT-America-Standard-Regular.woff') format('woff'),
          url('/fonts/GT-America-Standard-Regular.ttf') format('truetype');
  }
  

  body {
    min-height: calc(100vh + 1px);
    overflow-x: hidden;
    scroll-behavior: smooth;
    background-color: ${theme.bgColorLight};
    font-family: ${theme.ffLight};
    color: ${theme.color};
  }

  input {
    font-family: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
    font-weight: normal;
  }

  picture,
  img,
  video {
    display: block;
    width: 100%;
  }

  figure {
    margin: 0;
  }

  button,
  button[type=submit] {
    padding: .8125rem 1.75rem;
    background-color: ${theme.colorDark};
    border-radius: 2em;
    font-size: 0.9375rem;
    letter-spacing: -0.03333333333em;

    @media ${bp.tablet} {
      padding: .8125rem 1.75rem;
      font-size: 1.25rem;
      letter-spacing: -0.05em;
    }

    &,
    i {
      color: ${theme.colorWhite};
    }
    
    &:hover,
    &:hover i {
      color: ${theme.colorWhite60};
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;//1px solid green;
    -webkit-text-fill-color: currentColor;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    font-size: 1em;
    font-family: ${theme.ffRegular};
    transition: background-color 5000s ease-in-out 0s;
  }

  .counter-dropdown {
    z-index: 300000;
    height: 3.75rem;
    padding: .5rem;
    border-radius: 2em;
    border: 1px solid ${theme.borderColor};

    button {
      width: 2.75rem;
      height: 2.75rem;
    }
  }

  // Lodgify
  .search-bar .inputs-container {
    justify-content: flex-start;
  }

  .dropdown,
  .DateInput {
    padding: .75rem 1.25rem;
  }

  .dropdown,
  .DateInput_1 {
    &:before {
      display: block;
      margin-bottom: .125rem;
      color: ${theme.colorDark};
      font-size: 1rem;
      line-height: 1;
      letter-spacing: -0.0625em;
      pointer-events: none;
    }
  }

  .dropdown,
  .input,
  input {
    font-family: inherit;
    font-size: .875rem;
    line-height: 1;
    letter-spacing: -0.05714285714em;
    color: ${theme.colorLight};

    .CalendarMonth_caption {
      color: ${theme.colorDark};
    }
  }

  .dropdown {
    flex-wrap: wrap;
    align-items: flex-start;

    svg {
      position: relative;
      top: -0.5rem;
      transform: scaleY(1.5);
    }

    .icon.clear svg {
      transform: scaleY(1);
    }

    path {
      fill: ${theme.colorDark};
    }

    .menu {
      & > .item {
        padding: 1.1875rem 1.4375rem;

        &.active:hover, &:hover, &.selected, &.selected:hover {
          background-color: ${theme.colorDark};
          color: ${theme.colorWhite};
        }
      }
    }
  }

  .dropdown .menu,
  .DayPicker__withBorder {
    border-radius: 1.875rem;
    overflow: hidden;
  }

  .DayPicker__withBorder {
    border: 1px solid ${theme.borderColor};
  }

  .input-container {
    padding-right: 0;

    @media ${bp.laptop} {
      margin-right: ${theme.gapTablet};
    }
  }

  .location-input-container {
    width: 9.6875rem;

    .dropdown:before {
      content: "Location";
    }
  }

  .dates-input-container {
    flex-basis: 20.875rem;
  }

  .guests-input-container {
    width: 8.4375rem;

    .dropdown:before {
      content: "Guests";
    }
  }

  .DateInput_1:before {
    content: "Check-out";
  }

  .DateInput_1:first-child {
    border-right: 1px solid ${theme.borderColor};

    &:before {
      content: "Check-in";
    }
  }

  .DateRangePicker > div,
  .DateRangePickerInput {
    height: 100%;
  }

  .DateInput {
    position: relative;
    height: 100%;
    
    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: .125rem;
      padding: 1.2rem 1.1rem 0;
    }
  }

  .DateRangePickerInput_arrow {
    display: none;
  }

  .DateInput_fangStroke {
    fill: ${theme.colorWhite};
  }

  .DateRangePickerInput_clearDates_svg {
    display: none;
  }

  .DayPicker_weekHeader_li,
  .CalendarMonth_table td {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 11.5vw !important;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: .8125rem;

    @media ${bp.tablet} {
      width: 3rem !important;
    }
  }

  .DayPicker_weekHeader_li {
    height: 11.5vw !important;

    @media ${bp.tablet} {
      height: 3rem !important;
    }
  }

  .CalendarMonth_table td {
    height: 11.5vw !important;
    outline: none;

    @media ${bp.tablet} {
      height: 3rem !important;
    }
  }

  .dropdown-container,
  .DateRangePicker {
    height: 3.75rem;
    border: 1px solid ${theme.borderColor};
    border-radius: 2em;
  }

  .dropdown-container {
    .icon {
      display: none;
    }
  }

  .DateRangePickerInput {
    display: flex;
  }

  .CalendarMonth_table {
    td {
      width: 3rem;
      height: 3rem;

      &:hover:not(:empty),
      &.CalendarDay__selected {
        background-color: ${theme.colorDark};
        color: ${theme.colorWhite};
      }
    }
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    background-color: ${theme.colorMedium};
    color: ${theme.colorWhite};
  }

  .CalendarDay__default {
    border-radius: 50%;
    overflow: hidden;
  }

  .button-container {
    margin-right: 0;
    margin-left: auto;
  }

  button[type=submit] {
    margin-right: 0;
    margin-left: auto;
  }
  
  .DayPicker_portal__horizontal {
    width: 100vw !important;
    margin-left: -50vw !important;

    & > div > div {
      width: 100vw !important;
    }
  }

  .DateRangePicker_picker {
    z-index: 1;
  }

  .DayPicker {
    @media (max-width: ${bpSizes.tablet}) {
      max-width: 100vw;
    }
  }

  .DayPickerNavigation__horizontal {
    @media (max-width: ${bpSizes.tablet}) {
      max-width: 100vw;
    }
  }

  .DateInput_input {
    @media (max-width: ${bpSizes.tablet}) {
      caret-color: transparent;
    }
  }
  
  .DateRangePicker_picker__portal,
  .SingleDatePicker_picker__portal {
    z-index: 12000;
    color: ${theme.colorDark};
  }

  .DateRangePicker .DateRangePicker_picker,.DateRangePicker .SingleDatePicker_picker,.SingleDatePicker .DateRangePicker_picker,.SingleDatePicker .SingleDatePicker_picker {
    &,
    .CalendarMonth_caption {
      color: ${theme.colorDark};
    }   
  }

  // Legal monster
  .legalmonster-cleanslate.lm-base-root.lm-modal-root {
    font-family: ${theme.ffLight} !important;

    p,
    span,
    h1,
    h2,
    h3 {
      font-family: ${theme.ffLight} !important;
    }

    p {
      opacity: .5;
      font-size: 1rem;
      line-height: 1.125;

      strong {
        font-weight: normal !important;
      }
    }

    h1,
    h2,
    h3 {
      font-weight: normal !important;
    }

    h1,
    h2 {
      font-size: 1.375rem;
    }

    h3 {
      font-size: 1.2rem;
    }

    .lm-shield-container {
      display: none !important;
    }

    .lm-cookie-wall-container {
      .lm-cookie-wall-widget {
        svg {
          width: 2.625rem !important;
          height: 2.625rem !important;
          max-width: none !important;
          max-height: none !important;
          stroke-width: 1 !important;

          circle {
            display: none;
          }
        }

        .lm-header-container {
          padding: 1rem !important;

          @media ${bp.tablet} {
            padding: 1.375rem 2rem 1.4rem !important;
          }
        }

        .lm-body-container {
          padding: 1rem !important;

          @media ${bp.tablet} {
            padding: 1.4rem 2rem 1.375rem !important;
          }

          .lm-consent-container {
            margin-left: 0 !important;
            margin-right: 0 !important;

            .lm-checkbox {
              width: 1.375rem !important;
              height: 1.375rem !important;
              border: 1px solid ${theme.borderColor} !important;
              border-radius: 50% !important;
              background-color: transparent !important;;

              &:after {
                top: 0.25rem !important;
                left: 0.45rem !important;
                width: 0.4rem !important;
                height: 0.6rem !important;
                border-width: 0 1px 1px 0 !important;
                border-color: ${theme.colorDark} !important;
                transform: rotate(36deg) !important;
              }
            }
          }

          .lm-buttons-container {
            padding: 0 !important;
          }
        }
      }
    }

    .lm-privacy-container.lm-specificity-modifier {
      .lm-navigation-bar {
        padding-right: 1rem !important;
        padding-left: 1.375rem !important;
      }

      .lm-navigation-bar-icon {
        display: none !important;
      }

      .lm-slider.lm-round {
        &:before {
          /* border: 1px solid ${theme.borderColor} !important; */
        }

        svg {
          stroke-width: 1 !important;
        }
      }
    }

    .lm-mobile-test-purpose {
      .lm-cookie-information.lm-specificity-modifier {
        .lm-cookie-info-container {
          .lm-cookie-info-header {
            header {
              .lm-cookie-arrow-back {
                background-color: transparent !important;
                border: 1px solid ${theme.borderColor} !important;

                svg {
                  stroke: ${theme.colorDark} !important;
                  stroke-width: 1;
                }
              }
            }
          }
        }
      }
    }

    .switch {
      background-color: ${theme.borderColor} !important;
      border: 1px solid ${theme.borderColor} !important;
    }
  }


  // Transitions
  .c-page {
    /* will-change: opacity; */

    &--enter {
      opacity: 0;
    }

    &--enter-active {
      transition: opacity 300ms 500ms ease-in-out;
      opacity: 1;
    }

    &--enter-done {
      /* opacity: 1; */
    }

    &--exit {
      opacity: 1;
    }

    &--exit-active {
      transition: opacity 300ms 700ms ease-in-out;
      /* transition: opacity 300ms 0ms ease-in-out; */
      opacity: 0;
      pointer-events: none;

      /* &.s-no-delay {
        transition-delay: 0ms;
      } */
    }
  }

  .c-destination {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30000;
    will-change: transform, opacity;

    &--enter {
      position: fixed;
      /* opacity: 0; */
      opacity: 1;
      transform: translate3d(0, 100vh, 0);
    }

    &--enter-active {
      /* transition: opacity 400ms 300ms ease-out, transform 700ms ease-out; */
      transition: transform 1000ms cubic-bezier(0.33, 1, 0.68, 1); //easeOutCubic
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &--enter-done {
      /* opacity: 1; */
    }

    &--exit {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &--exit-active {
      position: fixed;
      /* transition: opacity 400ms ease-in, transform 700ms ease-in; */
      transition: transform 700ms cubic-bezier(0.32, 0, 0.67, 0); //easeInCubic
      /* opacity: 0; */
      opacity: 1;
      transform: translate3d(0, 100vh, 0);
      pointer-events: none;
    }
  }
`;
 
export default GlobalStyle;