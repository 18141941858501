import React from 'react';
import { useQuery } from 'graphql-hooks'
import { Helmet } from 'react-helmet-async';
import { renderMetaTags } from "react-datocms";
import styled, { css } from 'styled-components';
import { motion } from "framer-motion"
import { Link } from "react-router-dom";

import { ColumnsContainer } from '../../theme/Components';
import { theme, bp, bpSizes } from '../../theme/GlobalStyle';
import LocationMarker from '../../theme/LocationMarker';
import BookingButton from '../bookingButton/BookingButton';

import 'flickity/dist/flickity.min.css';
import Flickity from 'react-flickity-component'

const DESTINATION_QUERY = `query Destination($slug: String) {
  destination(filter: {slug: { eq: $slug} }) {
    title
    seo: _seoMetaTags {
      attributes
      content
      tag
    }
    aboutHeadline
    aboutText
    amenities {
      title
    }
    description
    gallery {
      responsiveImage {
        srcSet
        src
        webpSrcSet
        title
      }
    }
    location
    locationLink
    lodgifyId
    mapEmbed
    slug
    tagLine
  }
}`;

const Container = styled(motion.div)`
  background-color: ${theme.bgColorLight};

  h2 {
    color: ${theme.colorDark};
    font-size: 1.375rem;
    line-height: 1.0909090909;
    letter-spacing: -0.06818181818em;
  }
`;

const Close = styled.div`
  position: fixed;
  top: 1.875rem;
  right: 0;
  left: 0;
  z-index: 1;

  @media ${bp.tablet} {
    top: 1.375rem;
  }
`;

const CloseLinkButton = css`
  position: absolute;
  right: ${theme.gutter};
  display: block;
  margin-right: ${theme.gap};
  padding: .75rem;
  border-radius: 2rem;
  background-color: ${theme.colorWhite};
  cursor: pointer;
  color: ${theme.colorDark};
  text-decoration: none;

  @media ${bp.tablet} {
    margin-right: 0;
    padding: .5rem 1.6875rem;
  }

  &:hover {
    color: ${theme.colorMedium};
  }
`;
/* const CloseLink = styled.a` */
const CloseLink = styled(Link)`
  ${CloseLinkButton}
`;

// const CloseButton = styled.a`
//   ${CloseLinkButton}
// `;

const CloseIcon = styled.div`
  position: relative;
  width: 1.125rem;
  height: 1.125rem;
  transform: rotate(-45deg);

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: currentColor;
  }

  &:before {
    transform: translateZ(0) rotate(-90deg);
  }

  &:after {
    transform: translateZ(0);
  }

  @media ${bp.tablet} {
    display: none;
  }
`;

const CloseLabel = styled.p`
  display: none;
  margin: 0;
  font-size: 1.25rem;
  letter-spacing: -0.05em;
  font-family: ${theme.ffRegular};

  @media ${bp.tablet} {
    display: block;
  }
`;

const TopContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: span ${theme.columns};
  height: calc(66vh - 3.125rem);
  margin-top: 1.875rem;
  padding-right: ${theme.gap};
  border-right: 1px solid ${theme.borderColor};
  font-size: .75rem;
  line-height: 1.25;
  letter-spacing: -0.0625em;

  @media ${bp.tablet} {
    grid-column-start: 7;
    grid-column-end: span ${theme.columns / 2};
    height: auto;
    margin-top: .875rem;
    padding-right: 0;
    border-right: none;
    font-size: 0.875rem;
    line-height: 1.1428571429;
    letter-spacing: -0.06285714286em;
  }

  & > div {
    grid-template-rows: auto auto 5.25rem;
    height: 100%;

    @media ${bp.tablet} {
      /* grid-template-rows: auto 15.625rem; */
      grid-template-rows: auto auto;
      grid-template-columns: repeat(${theme.columns / 2}, 1fr);
      row-gap: 0;
    }
  }
`;

const DescriptionContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: span ${theme.columns / 2};
  height: 100%;
  
  @media ${bp.tablet} {
    grid-column-end: span 2;
    width: calc(100% + ${theme.gutter});
    padding-right: ${theme.gapTablet};
    border-right: 1px solid ${theme.borderColor};
  }
`;

const Description = styled.p`
  margin-top: 0%;
  color: ${theme.colorDark};
`;

const LocationCommon = css`
  margin-top: 1.375rem;

  @media ${bp.tablet} {
    width: calc(100% + ${theme.gapTablet});
    min-height: 12rem;
    margin-top: 2.6875rem;
    padding-top: 0.3125rem;
    padding-right: .25rem;
    border-top: 1px solid ${theme.borderColor};
  }

  @media ${bp.laptop} {
    margin-top: 6.6875rem;
  }

  p {
    margin-top: 0;
    margin-bottom: .3rem;
  }

  svg {
    width: 0.6875rem;
  }
`;

const Location = styled.div`
  ${LocationCommon}
`;

const LocationLink = styled.a`
  ${LocationCommon}
  display: block;
  color: ${theme.color};
  text-decoration: none;
`;

const Gallery = styled.div`
  grid-column-start: 1;
  grid-column-end: span ${theme.columns};
  position: relative;
  left: -${theme.gutter};
  width: calc(100% + ${theme.gutter} * 2);

  @media ${bp.tablet} {
    grid-row-start: 1;
    grid-column-end: span ${theme.columns / 2};
    left: 0;
    width: 100%;
  }

  .flickity-page-dots {
    z-index: 1;
    display: flex;
    bottom: -2.625rem;
    left: ${theme.gutter};
    width: calc(100% - ${theme.gutter} * 2);

    @media ${bp.tablet} {
      left: 0;
      width: 100%;
    }
  }

  .dot {
    position: relative;
    flex: 1 1 100%;
    margin: 0;
    height: 2.75rem;
    border-radius: 0;
    background: transparent;
    opacity: 1;

    &:first-child:last-child {
      display: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      height: 1px;
      background-color: ${theme.borderColor};
    }
    
    &.is-selected:after {
      background-color: ${theme.colorDark};
    }
  }

`;

const GalleryImage = styled.picture`
  
`;

const TitleContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: span ${theme.columns};
  margin-top: auto;
  margin-bottom: 0;
  text-align: right;
  font-size: 1.75rem;
  line-height: 1.25;
  letter-spacing: -0.0625em;

  @media ${bp.tablet} {
    grid-column-start: ${theme.columns / 4};
    grid-column-end: span ${theme.columns / 4 + 1};
    font-size: 2.625rem;
    line-height: 1.15;
  }
`;

const Title = styled.h1`
  margin: 0;
  color: ${theme.colorDark};
  font-size: 1em;
`;

const TagLine = styled.p`
  margin: 0;
  color: ${theme.colorDark20};
  font-size: 1em;
`;

const BookingButtonContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: span ${theme.columns};
  width: calc(100% + ${theme.gap});
  margin-top: auto;
  margin-bottom: 0;
  padding-top: 0.75rem;
  padding-right: .25rem;
  border-top: 1px solid ${theme.borderColor};

  @media ${bp.tablet} {
    position: absolute;
    bottom: 0;
    grid-column-end: span 2;
    display: flex;
    align-items: flex-end;
    width: calc(100% + ${theme.gapTablet});
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;//1.25rem;
    padding-top: 0;
    padding-right: ${theme.gapTablet};
    border-top: none;
    border-right: 1px solid ${theme.borderColor};
  }

  @media ${bp.laptopL} {
    padding-right: 0.25rem;
  }

  & > div {
    @media (min-width: ${bpSizes.tablet}) and (max-width: ${bpSizes.laptopL}) {
      flex-direction: column-reverse;
      align-items: flex-start;
      width: 100%;
    }

    a {
      margin-top: 0.5rem;
      
      @media (min-width: ${bpSizes.tablet}) and (max-width: ${bpSizes.laptopL}) {
        width: 100%;
        text-align: center;
      }
    }

    p {
      @media (min-width: ${bpSizes.tablet}) and (max-width: ${bpSizes.laptop}) {
        margin-bottom: .4rem;
        margin-left: 0rem;
      }

      @media (min-width: ${bpSizes.laptop}) and (max-width: ${bpSizes.laptopL}) {
        margin-bottom: .4rem;
        margin-left: 1.25rem;
      }
    }
  }
`;

const AboutContainer = styled.div`
  grid-column-end: span ${theme.columns};
  margin-top: 2.1875rem;

  @media ${bp.tablet} {
    grid-column-start: 7;
    grid-column-end: span 5;
    margin-top: 3.5625rem;
  }
`;

const AboutHeadline = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
`;

const AboutText = styled.div`
  margin-top: 1.4375rem;
  font-size: 1rem;
  line-height: 1.1875;
  letter-spacing: -0.0625em;
`;

const AmenitiesContainer = styled.div`
  grid-column-end: span ${theme.columns};

  @media ${bp.tablet} {
    grid-column-start: 7;
    grid-column-end: span 5;
  }
`;

const AmenitiesHeadline = styled.h2`
  margin-top: 1.75em;

  @media ${bp.tablet} {
    margin-top: 1.25em;
  }
`;

const AmenitiesList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Amenity = styled.p`
  margin: 0 0.1875rem 0.3125rem 0;
  padding: 0.3125rem .9375rem;
  border: 1px solid currentColor;
  border-radius: 2rem;
  font-size: .8125rem;
  line-height: 1;
  letter-spacing: -0.05538461538em;
`;

const MapContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: span ${theme.columns};
  margin-top: .625rem;
  margin-bottom: 2.3125rem;

  @media ${bp.tablet} {
    grid-column-start: 7;
    grid-column-end: span 6;
    margin-bottom: 7.5rem;
  }
`;

const MapHeadline = styled.h2``;

const Map = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 150%;
  border-radius: 1.25rem;
  overflow: hidden;

  @media ${bp.tablet} {
    padding-bottom: 100%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;


class Destination extends React.Component {

  constructor( props ) {

    super( props );

    const id = ( props.params && props.params.id ) ? props.params.id : undefined;
    this.state = {
      id: id
    }

  }

  shouldComponentUpdate(nextProps, nextState) {
    
    return false;

  }

  render() {

    const { id } = this.state;
    return <DestinationInner id={id} />

  }

}

function DestinationInner( props ) {

  // let history = useHistory();
  
  // const [ stateId, setStateId ] = useState();

  // let { id } = useParams();
  // if ( id && !stateId ) setStateId( id );

  const { loading, error, data } = useQuery(DESTINATION_QUERY, {
    variables: {
      slug: props.id
    }
  });

  if (loading) return <></>;//'Loading...'
  if (error) return <></>;//'Something Bad Happened'

  const destination = data.destination;
  const meta = renderMetaTags(destination.seo);


  let description = <></>;
  if ( destination.description && destination.description.length ) {
   
    description = <Description>{destination.description}</Description>;

  }

  let location = <></>;
  if ( destination.location && destination.location.length ) {
   
    const location_inner = <>
        <p>Location<br />
        {destination.location}</p>
        <LocationMarker />
      </>;
    if ( destination.locationLink ) {
      location = <LocationLink href={destination.locationLink} target="_blank">
        {location_inner}
      </LocationLink>;
    } else {
      location = <Location>
        {location_inner}
      </Location>;
    }

  }

  const flickity_options = {
    pageDots: true,
    prevNextButtons: false
  };

  let gallery_images = destination.gallery.map( ( image, i ) => {
    const image_data = image.responsiveImage;
    return (
      <GalleryImage key={`galleryImage_${i}`}>
        <source srcSet={image_data.webpSrcSet} type="image/webp" />
        <source srcSet={image_data.srcSet} type="image/jpeg" /> 
        <img src={image_data.src} alt={image_data.title}></img>
      </GalleryImage>
    );
  } );

  let tag_line = <></>;
  if ( destination.tagLine && destination.tagLine.length ) {
   
    tag_line = <TagLine>{destination.tagLine}</TagLine>;

  }

  let about_headline = <></>;
  if ( destination.aboutHeadline && destination.aboutHeadline.length ) {
   
    about_headline = <AboutHeadline>{destination.aboutHeadline}</AboutHeadline>;

  }

  let about_text = <></>;
  if ( destination.aboutText && destination.aboutText.length ) {
   
    const about_text_html = { __html: destination.aboutText }
    about_text = <AboutText dangerouslySetInnerHTML={about_text_html} />;

  }

  const amenities = destination.amenities.map( ( amenity, i ) => <Amenity key={`amenity_${i}`}>{amenity.title}</Amenity> );
  const map_html = { __html: destination.mapEmbed };
  
  // let back = e => {
  //   e.stopPropagation();
  //   history.goBack();
  // };

  let close_link = <></>;
  // if ( history.length <= 2 ) {
    close_link = (
      <CloseLink to="/">
        <CloseIcon />
        <CloseLabel>Close</CloseLabel>
      </CloseLink>
    );
  // } else {
    // close_link = (
    //   <CloseButton onClick={back}>
    //     <CloseIcon />
    //     <CloseLabel>Close</CloseLabel>
    //   </CloseButton>
    // );
  // }


  return (
    
        <>
        <Helmet>{meta}</Helmet>
        
        <Container key={`destination_${props.id}`}>

          <Close>
            <ColumnsContainer outmost>
              {close_link}
            </ColumnsContainer>
          </Close>

          <ColumnsContainer outmost>
            <TopContainer>
              <ColumnsContainer>

                <DescriptionContainer>
                  {description}
                  {location}
                </DescriptionContainer>

                <TitleContainer>
                  <Title>{destination.title}</Title>
                  {tag_line}
                </TitleContainer>

                <BookingButtonContainer>
                  <BookingButton id={destination.lodgifyId} />
                </BookingButtonContainer>

              </ColumnsContainer>
            </TopContainer>

            <Gallery>
              <Flickity options={flickity_options}>
                {gallery_images}
              </Flickity>
            </Gallery>

          </ColumnsContainer>

          <ColumnsContainer outmost>
            <AboutContainer>
              {about_headline}
              {about_text}
            </AboutContainer>
          </ColumnsContainer>

          <ColumnsContainer outmost>
            <AmenitiesContainer>
              <AmenitiesHeadline>Amenities</AmenitiesHeadline>
              <AmenitiesList>{amenities}</AmenitiesList>
            </AmenitiesContainer>
          </ColumnsContainer>

          <ColumnsContainer outmost>
            <MapContainer>
              <MapHeadline>Explore the area</MapHeadline>
              <Map dangerouslySetInnerHTML={map_html} />
            </MapContainer>
          </ColumnsContainer>
          
        </Container>
    </>
  );

}

export default Destination;