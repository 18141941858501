import React from 'react';
import styled from 'styled-components';

const Audio = styled.audio`
  display: none;
`;




class BackgroundAudio extends React.Component {

  constructor(props) {
  
    super(props);

    this.audioRef = React.createRef();

  }


  canPlay = () => {
   
    if (
      this.audioRef &&
      this.audioRef.current &&
      !this.audioRef.current.paused &&
      !this.audioRef.current.ended &&
      this.audioRef.current.readyState > 2
    ) {

      this.props.autoPlayEnable();

    }

  }


  mute = () => {

    if ( this.audioRef && this.audioRef.current ) this.audioRef.current.volume = 0;

  }

  unMute = () => {

    if ( this.audioRef && this.audioRef.current ) this.audioRef.current.volume = 1;

  }
  

  render() {

    const { url, mute } = this.props;

    if ( url && url.length ) {
      
      if ( mute ) this.mute();
      if ( !mute ) this.unMute();

      return (
        <Audio src={url} playsInline autoPlay loop onPlay={() => this.canPlay()} ref={this.audioRef} />
      );
        
    }
      
    return <></>
      
  }

}

export default BackgroundAudio;