import styled from 'styled-components';
import { theme, bp, bpSizes } from '../../theme/GlobalStyle';
import { Link, useLocation } from "react-router-dom";

const Container = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  max-width: 39.375rem;
  height: 0;
  padding-bottom: 120%;
  color: ${theme.colorWhite};
  border-radius: 1.25rem;
  overflow: hidden;

  @media ${bp.tablet} {
    max-width: none;
    padding-bottom: 60%;
  }

  &:first-child:last-child {
    max-width: none;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
  }
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 1.125rem 1.125rem 1.125rem;

  @media ${bp.tablet} {
    padding: 1.6875rem 1.875rem 1.875rem;
  }
`;

const Headline = styled.h2`
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
  text-align: right;
  font-size: 1.375rem;
  letter-spacing: -0.06272727273em;

  @media ${bp.tablet} {
    font-size: 2.375rem;
    letter-spacing: -0.06263157895em;
  }
`;

const TagLine = styled.p`
  margin-top: 2rem;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.1875;
  letter-spacing: -0.0625em;

  @media ${bp.tablet} {
    margin-top: 4.5rem;
    font-size: 1.4375rem;
    line-height: 1.0454545455;
    letter-spacing: -0.06260869565em;
  }
`;

const Description = styled.div`
  margin-top: 0;
  max-width: 15em;
  opacity: .7;
  font-size: 1rem;
  line-height: 1.1875;
  letter-spacing: -0.0625em;

  @media ${bp.tablet} {
    margin-top: .25rem;
    font-size: 1.375rem;
    line-height: 1.0454545455;
    letter-spacing: -0.06272727273em;
  }
`;

const CTA = styled.p`
  margin-top: auto;
  margin-bottom: 0;
  margin-right: auto;
  padding: .9rem 1.9rem;
  background-color: ${theme.colorWhite};
  border-radius: 2em;
  color: ${theme.colorDark};
  font-family: ${theme.ffRegular};
  font-size: .9375rem;
  line-height: 1;
  letter-spacing: -0.05em;

  @media ${bp.tablet} {
    font-size: 1.25rem;
  }

  &:hover {
    color: ${theme.colorMedium};
  }
`;

function DestinationTeaser( props ) {

  let location = useLocation();

  const className = props.className && props.className.length ? props.className : '';

  let media = <></>;
  let image;
  
  const thumbnail = props.thumbnail;
  if ( thumbnail && thumbnail.responsiveImage ) {

    const media_type = thumbnail.mimeType.indexOf("image") > -1 ? "image" : "video";
    if ( media_type === "image" ) {
      image = thumbnail.responsiveImage
    }
  }

  if ( !image ) {

    const gallery = props.gallery;
    if ( gallery && gallery.length ) {
      const media_type = gallery[0].mimeType.indexOf("image") > -1 ? "image" : "video";
      if ( media_type === "image" ) {
        image = gallery[0].responsiveImage;
      }
    }
  }

  if ( image ) {
    media = (
      <picture>
        <source srcSet={image.webpSrcSet} type="image/webp" />
        <source srcSet={image.srcSet} type="image/jpeg" /> 
        <img src={image.src} sizes={`(min-width: ${bpSizes.tablet}) 50vw, (min-width: ${bpSizes.laptopL}) 675px, 75vw`} alt={image.title}></img>
      </picture>
    );
  }

  const description_html = { __html: props.description };

  return (
    <Container
      key={`destinationLink_${props.slug}`}
      onClick={props.destinationClicked}
      to={ {
        pathname: `/destination/${props.slug}`,
        state: {
          background: location,
          scrollY: window.scrollY
        }
      } }
      className={className}
    >
      
      {media}

      <Inner>

        <Headline>{props.title}</Headline>
        <TagLine>{props.tagLine}</TagLine>
        <Description dangerouslySetInnerHTML={description_html} />
        <CTA>Explore</CTA>

      </Inner>

    </Container>
  );

}

export default DestinationTeaser;