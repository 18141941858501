import React from 'react';
import styled from 'styled-components';
import { bp, theme } from '../../theme/GlobalStyle';
import { motion } from "framer-motion";

const Container = styled.div`
  padding-top: .75rem;
  border-top: 1px solid ${theme.borderColor};
  padding-left: ${theme.gap};
  color: ${theme.colorDark};

  @media ${bp.tablet} {
    padding-left: ${theme.gapTablet};
  }
`;

const Toggle = styled.a`
  display: flex;
  justify-content: space-between;
  padding-bottom: .3125rem;
  cursor: pointer;
`;

const Headline = styled.p`
  max-width: calc(100% - 2rem);
  margin: 0 0 1rem 0;
  padding-right: 1em;
  opacity: .5;

  .open & {
    opacity: 1;
  }
`;

const Icon = styled.div`
  position: relative;
  width: 1.25rem;
  height: 1.25rem;

  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: ${theme.colorMedium}; 
  }

  &:before {
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
  }

  &:after {
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    will-change: opacity;
    transition: opacity 100ms ease-in-out;
  }

  .open &:after {
    opacity: 0;
  }
`;

const Content = styled(motion.div)`
  overflow: hidden;
  height: 0;
  opacity: .5;
`;

const Inner = styled.div`
  padding-bottom: 2.4375rem;
`;

const variants = {
  open: { height: "auto" },
  closed: { height: 0 },
}


class AccordionItem extends React.Component {
  
  constructor( props ) {

    super( props )
    this.state = { isOpen: false }

  }


  handleToggleClicked = () => {

    const { isOpen } = this.state;
    this.setState( { isOpen: !isOpen } );

  }


  render() {

    const  { isOpen } = this.state;
    const { headline, text } = this.props;

    const text_html = { __html: text };

    return (
      <Container className={isOpen ? "open" : "closed"}>

        <Toggle onClick={ this.handleToggleClicked }>
          <Headline>{headline}</Headline>
          <Icon />
        </Toggle>
        
        <Content
          animate={isOpen ? "open" : "closed"}
          variants={variants}
          transition={{ ease: "easeInOut", duration: .25}}
        >
          <Inner dangerouslySetInnerHTML={text_html} />
        </Content>

      </Container>  
    );

  }

}

export default AccordionItem;