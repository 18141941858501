import styled from 'styled-components';
import AccordionItem from './AccordionItem';

const Container = styled.div`
  font-size: 1rem;
  line-height: 1.125;
  letter-spacing: -0.0625em;
`;

function Accordion( props ) {
  
  const items = props.items.map( ( item, i ) => {

    return <AccordionItem
      key={`accordionItem_${i}`}
      headline={item.headline}
      text={item.text} />;
      
  } );
  
  return (
    <Container>
      {items}
    </Container>
  );

}

export default Accordion;