import styled from 'styled-components';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { theme, bp } from '../../theme/GlobalStyle';

const Container = styled.div`
  margin-top: 4.375rem;
  font-size: 1rem;
  color: ${theme.colorLight};
`;

const Status = styled.p`
  position: absolute;
  bottom: 2.25em;
`;

const Inner = styled.div`
  position: relative;
`;

const Form = styled.form`
  position: relative;
  color: ${theme.colorLight};
  overflow: hidden;

  
`;

const Input = styled.input`
  &,
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
    appearance: none;
    font-size: 1.375rem;
    letter-spacing: -0.06045454545em;
  }

  position: relative;
  left: -${theme.gap};
  width: calc(100% + ${theme.gap});
  padding: .875rem 2.875rem .875rem ${theme.gap};
  border: none;
  border-bottom: 1px solid ${theme.borderColor};
  background-color: transparent;
  font-size: 1.375rem;
  outline: none;

  @media ${bp.tablet} {
    left: -${theme.gapTablet};
    width: calc(100% + ${theme.gapTablet});
    padding-left: ${theme.gapTablet}
  }
`;

const Submit = styled.button`
  position: absolute;
  top: .6rem;
  right: 0;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: 1px solid ${theme.colorLight};
  border-radius: 50%;
  background: transparent;
  cursor: pointer;

  &:hover {
    background-color: transparent;

    svg {
      transform: translateX(.125rem);
    }
  }

  svg {
    width: 1.25rem;
    height: auto;
    will-change: transform;
    transition: transform 200ms ease-out;
  }

  path {
    stroke: ${theme.colorDark};
  }
`;

const Message = styled.p`
  width: 17em;
  margin-top: 1.2rem;
`;


const updatedMessage = ( message ) => {

  if ( message === "0 - Please enter a value" ) return "Please enter email address";
  return message;

}


function MailchimpForm( props ) {

  const url = "//canopylife.us1.list-manage.com/subscribe/post?u=8eafe95b4e2a566ccdcc83f6f&id=b019dcbf27";

  const arrow = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21"><path d="M10.5 1.3l9.2 9.2-9.2 9.2"/><path strokeLinecap="square" d="M18.5 10.5H.5"/></svg>;

  return (
    <Container>
      <MailchimpSubscribe
        url={ url }
        render={ ( { subscribe, status, message } ) => (
          <>
            <Inner>
              
              {status === "sending" && <Status className="s-sending">Sending...</Status>}
              {status === "error" && <Status className="s-error" dangerouslySetInnerHTML={{__html: updatedMessage(message)}}/>}
              {status === "success" && <Status className="s-success">Subscribed!</Status>}

              <Form onSubmit={e => {
                e.preventDefault();
                const form = e.target;
                                      
                const formData = {
                  EMAIL: form.email.value,
                  // GDPR_NAME: form.gdpr.checked ? form.gdpr.value : '',
                };

                subscribe(formData);
              } }>

                <Input type="email" name="email" placeholder="Newsletter sign-up" />
                <Submit>{arrow}</Submit>

                {/* <input type="checkbox" placeholder="GDPR" id="gdpr" />
                <label htmlFor="gdpr">We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. <a href="https://mailchimp.com/legal/" target="_blank" rel="noreferrer">Learn more about Mailchimp's privacy practices here.</a></label> */}

              </Form>
            </Inner>

            <Message>Subscribe to receive information about Havnaert, experiences, stories, events and future updates.</Message>
          </>
        )}
      />
    </Container>
  );
}

export default MailchimpForm;