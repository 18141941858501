import React from 'react';
import { useInView } from "react-intersection-observer";
import styled from 'styled-components';
import { motion } from "framer-motion";
import { ColumnsContainer } from '../../theme/Components';
import { theme, bp } from '../../theme/GlobalStyle';

import 'flickity/dist/flickity.min.css';
import Flickity from 'react-flickity-component'


import DestinationTeaser from '../destinationTeaser/DestinationTeaser';

const Container = styled.div`
  padding: 0 ${theme.gutter} 4.1575rem;
  background-color: ${theme.bgColorLight};
  overflow: hidden;
`;

const Headline = styled(motion.h2)`
  grid-column-end: span ${theme.columns};
  margin: 5.0625rem auto 4.8125rem;
  text-align: center;
  color: ${theme.colorDark};
  font-size: 1.875rem;
  line-height: 1.3%;
  letter-spacing: -0.05em;

  @media ${bp.tablet} {
    margin-top: 7.4375rem;
    margin-bottom: 7.4375rem;
    font-size: 4.875rem;
    letter-spacing: -0.06666666667em;
  }
`;

const Destinations = styled(motion.div)`
  grid-column-end: span ${theme.columns};
  position: relative;
  left: -${theme.gutter};
  width: calc(100% + ${theme.gutter} * 2);

  @media ${bp.tablet} {
    left: 0;
    width: 100%;
  }

  .flickity-viewport {
    overflow: visible;
  }
`;

const DestinationTeaserContainer = styled(DestinationTeaser)`
  width: 80%;
  margin-left: ${theme.gutter};

  @media ${bp.tablet} {
    width: calc((100% - ${theme.gutter} * 1) / 2);
  }

  &:first-child:last-child {
    width: 100%;
    margin-left: 0;

    @media ${bp.tablet} {
      width: 100%;
    }
  }
`;


const variants = {
  visible: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 25,
    opacity: 0,
  },
}


const HeadlineComponent = function( props ) {
  
  const [ ref, inView ] = useInView( {
    threshold: 0.1,
    triggerOnce: true
  } );

 return (
  <Headline
    ref={ref}
    initial="hidden"
    animate={inView ? 'visible' : 'hidden' }
    variants={variants}
    transition={{ ease: "easeInOut", duration: .75}}
  >{props.headline}</Headline>
 );

};


const DestinationsComponent = function( props ) {
  
  const [ ref, inView ] = useInView( {
    threshold: 0.1,
    triggerOnce: true
  } );

 return (
  <Destinations
    ref={ref}
    initial="hidden"
    animate={inView ? 'visible' : 'hidden' }
    variants={variants}
    transition={{ ease: "easeInOut", duration: .75}}
   >{props.slideshow}</Destinations>
  
 );

};


class DestinationList extends React.Component {
  

  componentDidMount = () => {
    
    this.flkty.on( 'dragStart', () => this.handleDragStart() );
    this.flkty.on( 'dragEnd', () => this.handleDragEnd() );

  }


  handleDragStart = () => {

    this.flkty.slider.childNodes.forEach(slide => slide.style.pointerEvents = "none")

  }

  handleDragEnd = () => {

    this.flkty.slider.childNodes.forEach(slide => slide.style.pointerEvents = "all")

  }


  render() {

    const destinations = this.props.destinations.map( destination => {
      
      return (
        <DestinationTeaserContainer
          key={`destinationTease_${destination.id}`}
          destinationClicked={this.props.destinationClicked}
          className="c-destination-teaser"
          slug={destination.slug}
          title={destination.title}
          thumbnail={destination.thumbnail}
          gallery={destination.gallery}
          tagLine={destination.tagLine}
          description={destination.description}
        />
      );

    } );

    const flickity_options = {
      cellAlign: 'left',
      contain: true,
      pageDots: false,
      prevNextButtons: false
    };

    const slideshow = (
      <Flickity
        flickityRef={c => this.flkty = c}
        options={flickity_options}
      >
        {destinations}
      </Flickity>
    );

    return (
      <Container>

        <ColumnsContainer outmost>
          <HeadlineComponent headline={this.props.headline} />
        </ColumnsContainer>
        
        <ColumnsContainer outmost>
          <DestinationsComponent slideshow={slideshow} />
        </ColumnsContainer>

      </Container>
    );
  }
}

export default DestinationList;