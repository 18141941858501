import styled from 'styled-components';
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { ColumnsContainer } from '../../theme/Components';
import { theme, bp } from '../../theme/GlobalStyle';

const Container = styled.div`
  padding-bottom: ${props => props.plainLayout ? "2rem" : "0"};
  background-color: ${props => props.plainLayout ? theme.bgColorLight : theme.bgColor };

  @media ${bp.tablet} {
    padding-bottom: ${props => props.plainLayout ? "4rem" : "0"};
  }
`;

const Headline = styled(motion.h2)`
  grid-column-start: 1;
  grid-column-end: ${theme.columns + 1};
  margin-top: 2rem;
  margin-bottom: 0;
  color: ${theme.colorDark};
  font-size: ${props => props.plainLayout ? 1.375 : 1.75 }rem;
  line-height: ${props => props.plainLayout ? 1.0909090909 : 1.1071428571 };
  letter-spacing: ${props => props.plainLayout ? -0.06818181818 : -0.0525}em;

  @media ${bp.tablet} {
    grid-column-start: 4;
    grid-column-end: span 4;
    margin-top: 1rem;
    font-size: ${props => props.plainLayout ? 1.375 : 2.375 }rem;
    line-height: ${props => props.plainLayout ? 1.0909090909 : 1.0526315789 };
    letter-spacing: ${props => props.plainLayout ? -0.06818181818 : -0.05263157895 }em;
  }
`;

const TextContainer = styled(motion.div)`
  grid-column-start: 1;
  grid-column-end: ${theme.columns + 1};
  margin-top: ${props => props.plainLayout ? "0" : "3.875rem"};
  color: ${theme.colorMedium};
  font-size: ${props => props.plainLayout ? "1rem" : "1.75rem"};
  line-height: ${props => props.plainLayout ? "1.1875" : "1.1071428571"};
  letter-spacing: ${props => props.plainLayout ? "-0.05em" : "-0.04357142857em"};
  text-indent: ${props => props.plainLayout ? "0" : "5em"};

  @media ${bp.tablet} {
    grid-column-start: 4;
    grid-column-end: ${props => props.plainLayout ? "span 5" : theme.columns + 1};
    margin-top: ${props => props.plainLayout ? "0" : "16rem"};
    font-size: ${props => props.plainLayout ? "1rem" : "2.375rem"};
    line-height: ${props => props.plainLayout ? "1.1875" : "1.0526315789"};
    letter-spacing: ${props => props.plainLayout ? "-0.05em" : "-0.04342105263em"};
    text-indent: ${props => props.plainLayout ? "0" : "10em"};
  }
`;

const SecondaryTextContainer = styled(motion.div)`
  grid-column-start: 1;
  grid-column-end: ${theme.columns + 1};
  margin-top: 2rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  line-height: 1.1875;
  letter-spacing: -0.0525em;

  @media ${bp.tablet} {
    grid-column-end: span 3;
    margin-top: 4.5rem;
  }
`;

const variants = {
  visible: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 25,
    opacity: 0,
  },
}

const HeadlineComponent = function( props ) {
  
  const [ ref, inView ] = useInView( {
    threshold: 0.1,
    triggerOnce: true
  } );

 return (
  <Headline
    plainLayout={props.plainLayout}
    ref={ref}
    initial="hidden"
    animate={inView ? 'visible' : 'hidden' }
    variants={variants}
    transition={{ ease: "easeInOut", duration: .75}}
  >{props.headline}</Headline>
 );

};


const TextComponent = function( props ) {
  
  const [ ref, inView ] = useInView( {
    threshold: 0.1,
    triggerOnce: true
  } );

 return (
  <TextContainer
    plainLayout={props.plainLayout}
    ref={ref}
    initial="hidden"
    animate={inView ? 'visible' : 'hidden' }
    variants={variants}
    transition={{ ease: "easeInOut", duration: .75}}
    dangerouslySetInnerHTML={props.content} />
 );

};


const SecondatyTextComponent = function( props ) {
  
  const [ ref, inView ] = useInView( {
    threshold: 0.1,
    triggerOnce: true
  } );

 return (
  <SecondaryTextContainer
    plainLayout={props.plainLayout}
    ref={ref}
    initial="hidden"
    animate={inView ? 'visible' : 'hidden' }
    variants={variants}
    transition={{ ease: "easeInOut", duration: .75}}
  >{props.text}</SecondaryTextContainer>
 );

};


function Text( props ) {

  const { headline, plainLayout,secondaryText, text } = props;
  let secondary_text = <></>;
  if ( !plainLayout && secondaryText && secondaryText.length ) {
    secondary_text = <SecondatyTextComponent text={secondaryText} />;
  }

  const text_html = { __html: text};

  return (
    <Container
      plainLayout={plainLayout}
    >
      <ColumnsContainer outmost noRowGap>

        <HeadlineComponent plainLayout={plainLayout} headline={headline} />
        <TextComponent plainLayout={plainLayout} content={text_html} />
        {secondary_text}

      </ColumnsContainer>
    </Container>
  );
}

export default Text;