import React from 'react';
import styled from 'styled-components';
import { theme, bp } from '../../theme/GlobalStyle';
import { ColumnsContainer } from '../../theme/Components';


// import { getAvailability, getProperties, getLocations } from '@lodgify/websites-service-client';
import { SearchBar } from '@lodgify/ui';
import './lodgify-ui.css';
// import '@lodgify/ui/lib/styles/lodgify-ui.css';

import { Button } from '@lodgify/ui/lib/commonjs/components/elements/Button';

function formatDateForURL( date ) {

  const d = new Date(date);
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  return `${ye}${mo}${da}`;

};

const Container = styled.div`
  background-color: ${theme.bgColorLight};
`;

const Inner = styled.div`
  grid-column-start: 1;
  grid-column-end: ${theme.columns + 1};
  padding-top: .625rem;
  padding-bottom: 1.875rem;

  @media ${bp.tablet} {
    grid-column-start: 4;
  }
`;


export default class BookingSearch extends React.Component {
  
  
  constructor( props ) {
    
    super( props );
    
    const controller = new AbortController();
    const { signal } = controller;

    this.state = {

      controller: controller,
      signal: signal,
      location: null,
      locations: [ { text: "Loading locations", value: `-1` } ],
      guests: "–",
      isOpen: false
      
    }

    this.handleSearchBarChange = this.handleSearchBarChange.bind(this);

  }
  

  componentDidMount() {

    const { signal } = this.state;

    fetch("https://api.lodgify.com/v2/properties", {
      
      signal: signal,
      headers: new Headers( {

        "X-ApiKey": `${process.env.REACT_APP_LODGIFY_TOKEN}`,

      } ),

    } )
    .then( res => res.json() )
    .then( ( result ) => {

      if ( result.items && result.items.length ) {

        const locations_options = result.items.map( location => {

          return { text: location.name, value: `${location.id}` };

        } );

        this.setState( { locations: locations_options } );

      }

    },
    ( error ) => { console.log( error ) } );
          
  }


  componentWillUnmount() {

    this.state.controller.abort();

  }


  handleInputChange( values ) {
    console.log(values);
  }


  handleContainerClicked = () => {

    const { isOpen } = this.state;
    if ( !isOpen ) this.setState( { isOpen: !isOpen} );

  }


  handleHitFieldClicked = () => {

    const { isOpen } = this.state;
    if ( isOpen ) this.setState( { isOpen: !isOpen} );

  }


  isDayBlocked( day ) {

    return false;

  }


  handleSearchBarChange( values ) {

    // const { guests, location, signal } = this.state;
    const { guests } = this.state;
    if ( values && values.guests && values.guests !== guests ) {

      this.setState( { guests: values.guests } );

    }


    // if ( values && values.location && values.location !== location ) {
      
    //   console.log(values.location);
    //   fetch(`https://api.lodgify.com/v2/availability`, {
      
    //     mode: 'no-cors',
    //     signal: signal,
    //     headers: new Headers( {

    //       "X-ApiKey": `${process.env.REACT_APP_LODGIFY_TOKEN}`

    //     } ),

    //   } )
    //   .then( res => res.json() )
    //   .then( ( result ) => {

    //     console.log(result)
        
    //   },
    //   ( error ) => { console.log( error ) } );

    // }
    
  }

  handleSearchBarSubmit( values ) {
    
    if ( window.innerWidth < theme.tablet && !this.state.isOpen ) {
    
      this.setState( { isOpen: true } );

    } else if (
      values &&
      values.location &&
      values.dates &&
      values.dates.startDate &&
      values.dates.endDate &&
      values.guests) {

      const location = values.location;
      const start_date = formatDateForURL(values.dates.startDate._d);
      const end_date = formatDateForURL(values.dates.endDate._d);
      const guests = values.guests;
      
      if ( location && start_date && end_date && guests ) {
        
        const url = `https://checkout.lodgify.com/${process.env.REACT_APP_LODGIFY_PROFILE}/en/?currency=EUR#/${location}/${start_date},${end_date},${guests}/-`
        window.open(url,'_blank');

      }

    }

  }


  render() {

    const { locations, guests } = this.state;
    
    if ( locations && locations.length ) {

      const search_button = <Button isFormSubmit>Book</Button>;

      return (
        <Container>
          <ColumnsContainer outmost>
            <Inner>
              <SearchBar
                locationOptions={ locations }
                locationInputLabel="–"
                guestsInputLabel={`${guests}`}
                isCalendarIconDisplayed={false}
                datesCheckInLabel="–"
                datesCheckOutLabel="–"
                getIsDayBlocked={this.isDayBlocked}
                isStackable={true}
                onChangeInput={this.handleSearchBarChange}
                onSubmit={this.handleSearchBarSubmit}
                searchButton={search_button}
              />
            </Inner>
          </ColumnsContainer>
        </Container>
      );

    }

    return <></>;

  }

}