import React from 'react';
import styled from 'styled-components';

import { ColumnsContainer } from '../../theme/Components';

const Guides = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 111111111;
  display: ${props => props.active ? 'block' : 'none' };;
  justify-content: center;
  pointer-events: none;
`;

const Column = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  outline: 1px solid blue;
  color: #FF00FF;
  text-shadow: 0 0 1em #000000;
`;


export default class Dev extends React.Component {

  constructor( props ) {

    super( props );

    this.state = {

      active: false,
      ctrlPressed: false

    };

  }


  componentDidMount() {

    document.addEventListener("keydown", ( event ) => this.handleKeyDown( event ), false );
    document.addEventListener("keyup", ( event ) => this.handleKeyUp( event ), false );

  }


  handleKeyDown( event ) {

    if ( event.key === "Control" ) this.setState( { ctrlPressed: true } );

  }


  handleKeyUp( event ) {

    const { active, ctrlPressed } = this.state;
    const new_state = {};

    if ( event.key === "Control" ) new_state.ctrlPressed = false;
    if ( event.key === 'l' && ctrlPressed ) new_state.active = !active;

    this.setState( new_state );

  }


  render() {
 
    const { active } = this.state;
    
    return (
      <div className="c-dev">
        <Guides active={active}>
          <ColumnsContainer outmost fullHeight>

            <Column><p>1</p></Column>
            <Column><p>2</p></Column>
            <Column><p>3</p></Column>
            <Column><p>4</p></Column>
            <Column><p>5</p></Column>
            <Column><p>6</p></Column>
            <Column><p>7</p></Column>
            <Column><p>8</p></Column>
            <Column><p>9</p></Column>
            <Column><p>10</p></Column>
            <Column><p>11</p></Column>
            <Column><p>12</p></Column>

          </ColumnsContainer>
        </Guides>
      </div>
    );

  }

}