import React from "react";
import { InView } from "react-intersection-observer";
import styled from 'styled-components';
import { motion } from "framer-motion"
import { ColumnsContainer } from '../../theme/Components';
import { theme, bp } from '../../theme/GlobalStyle';

const Container = styled(motion.div)`
  position: relative;
  height: 100vh;
  max-height: 90vh;
  overflow: hidden;
  background-color: ${theme.bgColorLight};
  color: ${theme.colorWhite};
`;

const Inner = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  align-items: center;
`;

const HeadlineContainer = styled(motion.div)`
  grid-column-end: span ${theme.columns};
  margin: auto auto 6.25rem;
  text-align: center;

  @media ${bp.tablet} {
    max-width: none;
    margin-bottom: auto;
  }
`;

const Headline = styled(motion.h2)`
  max-width: 7em;
  margin: 0;
  font-size: ${props => props.extendedLayout ? '2.875' : '1.875'}rem;
  line-height: ${props => props.extendedLayout ? '1.0869565217' : '1.0869565217'}; // !extendedLayout Not defined in design
  letter-spacing: ${props => props.extendedLayout ? '-0.07152173913' : '-0.03333333333'}em;

  @media ${bp.tablet} {
    max-width: none;
    margin-top: 30rem;
    font-size: ${props => props.extendedLayout ? '6.5' : '4.875'}rem;
    line-height: ${props => props.extendedLayout ? '1.0833333333' : '1.0833333333'}; // !extendedLayout Not defined in design
    letter-spacing: ${props => props.extendedLayout ? '-0.06666666667' : '-0.06256410256'}em;
  }
`;

const SecondaryText = styled(motion.p)`
  position: absolute;
  left: -${theme.gap};
  grid-column-start: 6;
  grid-column-end: ${theme.columns + 1};
  max-width: 12em;
  margin-top: 1.25rem;
  font-size: 1.0625rem;
  line-height: 1.1764705882;
  letter-spacing: -0.06235294118em;

  @media ${bp.tablet} {
    left: 0;
    grid-column-start: 4;
    margin-top: 1rem;
    font-size: 1.5rem;
    line-height: 1.125;
    letter-spacing: -0.0625em;
  }
`;

const MediaContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  picture,
  img,
  video {
    height: 100%;
    object-fit: cover;
  }

  video::-webkit-media-controls-overlay-play-button {
    display: none;
  }
`;

const Mute = styled.div`
  position: absolute;
  bottom: .25rem;
  left: ${theme.gutter};
  display: none;
  height: 2.75rem;
  cursor: pointer;

  @media ${bp.tablet} {
    display: flex;
    align-items: center;
  }

  svg {
    height: 1rem;
  }

  p {
    margin: 0 0 0 .5rem;
    letter-spacing: -0.0625em;
  }
`;


const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};


export default class Hero extends React.Component {
  
  constructor( props ) {

    super( props );
    this.state = {

      mediaReady: false,
      muted: false,

    };

  }

  shouldComponentUpdate( nextProps, nextState ) {

    const { muted } = this.state;
    
    if ( !this.state.mediaReady && nextState.mediaReady ) return true;
    if ( muted !== nextState.muted ) return true;

    return false;

  }


  mediaReady = () => {

    if ( this.props.topMost ) this.props.heroMediaReady();
    this.setState( { mediaReady: true } );

  }


  muteClicked = () => {

    this.setState( { muted: !this.state.muted } );
    this.props.muteClicked();

  }
  

  render() {

    const { mediaReady, muted } = this.state;
    const { autoPlayEnabled, extendedLayout, headline, media, secondaryText, topMost, useExternalVideo, videoSource } = this.props;

    let muteIcon = <></>;
    
    if ( topMost ) {
        
      if ( autoPlayEnabled ) {
          
        const muteText = muted ? "Unmute" : "Mute";
        muteIcon = (
          <Mute onClick={() => this.muteClicked()}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 15"><path fill="#fff" fillRule="evenodd" d="M10.2 14.85c.33.24.8 0 .8-.4V.55a.5.5 0 00-.8-.4l-5 3.7H.74a.5.5 0 00-.5.5v6.3c0 .28.22.5.5.5H5.2l5 3.7z" clipRule="evenodd"/></svg>
          <p>{muteText}</p>
        </Mute>
        );

      }

    }

    let secondary_text = <></>;
    if ( extendedLayout && secondaryText && secondaryText.length ) {
      secondary_text = (
        <SecondaryText
          initial="hidden"
          animate={mediaReady ? 'visible' : 'hidden' }
          variants={variants}
          transition={{ ease: "easeInOut", delay: 1.5, duration: 1}}
        >{secondaryText}</SecondaryText>
      );
    }

    let media_element = <></>;
    if ( useExternalVideo && videoSource && videoSource.length ) {

      media_element = (
        <video
          src={videoSource}
          playsInline
          muted
          autoPlay
          loop
          onLoadedData={ () => this.mediaReady() }
        />
      );

    } else {

      const media_type = media.mimeType.indexOf("image") > -1 ? "image" : "video";
      if ( media_type === "image" ) {
        
        const image = media.responsiveImage;
        media_element = (
          <picture onLoad={ () => this.mediaReady() }>
            <source srcSet={image.webpSrcSet} type="image/webp" />
            <source srcSet={image.srcSet} type="image/jpeg" /> 
            <img src={image.src} sizes="100vw" alt={image.title}></img>
          </picture>
        );
    
      }

    }
    
    return (
      <InView as="div" threshold={0.1} triggerOnce={true}>
        {({ inView, ref }) => (
          <Container
            ref={ref}
            topMost={topMost}
          >
            
            <MediaContainer
              initial="hidden"
              animate={mediaReady ? "visible" : "hidden"}
              variants={variants}
              transition={{ ease: "easeInOut", duration: .5, delay: 0}}
            >
              {media_element}
            </MediaContainer>

            <Inner
              // initial="hidden"
              // animate={mediaReady && inView ? "visible" : "hidden"}
              // variants={variants}
              // transition={{ ease: "easeInOut", delay: .5, duration: 1}}
            >
              <ColumnsContainer outmost fullHeight>
                {secondary_text}
                <HeadlineContainer
                  initial="hidden"
                  animate={mediaReady && inView ? 'visible' : 'hidden' }
                  variants={variants}
                  transition={{ ease: "easeInOut", delay: .5, duration: 1}}
                >
                  <Headline
                    extendedLayout={extendedLayout}
                  >{headline}</Headline>
                </HeadlineContainer>
                {muteIcon}
              </ColumnsContainer>
            </Inner>

          </Container>
        )}
      </InView>
    );
  }
}