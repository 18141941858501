import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/App';
// import * as serviceWorker from './serviceWorker';
import { GraphQLClient, ClientContext } from 'graphql-hooks'
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from "react-router-dom";

const client = new GraphQLClient({
  url: "https://graphql.datocms.com/",
  headers: {
    "Authorization": `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
  }
});


ReactDOM.render(
  // <React.StrictMode>
    <ClientContext.Provider value={client}>
      <HelmetProvider>
        <Router>
          <App />
        </Router>
      </HelmetProvider>
    </ClientContext.Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
