import styled from 'styled-components';
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion"
import { ColumnsContainer } from '../../theme/Components';
import { theme, bp, bpSizes } from '../../theme/GlobalStyle';

const Container = styled.div`
  padding-top: 1.15625rem;
  padding-bottom: 1.15625rem;
  background-color: ${theme.bgColor};

  @media ${bp.tablet} {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
`;

const MediaContainer = styled.figure`
  grid-column-start: 1;
  grid-column-end: ${theme.columns + 1};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${theme.gap};

  @media ${bp.tablet} {
    grid-template-columns: repeat(9, 1fr);
    grid-column-gap: ${theme.gapTablet};
    grid-column-end: span 9;
  }

  picture,
  img,
  video {
    grid-column-start: 1;
    grid-column-end: span 2;

    @media ${bp.tablet} {
      grid-column-end: span 6;
    }
  }

  figcaption {
    grid-column-start: 2;
    grid-column-end: span 1;
    margin-top: .75rem;
    color: ${theme.colorDark};
    font-size: .75rem;
    line-height: 1.25;
    letter-spacing: -0.0625em;
    
    @media ${bp.tablet} {
      position: relative;
      top: -.2em;
      grid-column-start: 7;
      grid-column-end: span 2;
      margin-top: 0;
      font-size: .875rem;
      line-height: 1.1428571429;
      letter-spacing: -0.05285714286em;
    }
  }

`;

const MediaCaption = styled.figcaption`
  display: block;
`;

const ContentContainer = styled.div`
  margin-top: .5rem;
  grid-column-start: 1;
  grid-column-end: ${theme.columns + 1};
  border-left: 1px solid ${theme.borderColor};
  padding-left: ${theme.gap};
  padding-bottom: 5.75rem;

  @media ${bp.tablet} {
    margin-top: 0;
    grid-column-start: 10;
    grid-column-end: span 2;
    position: relative;
    left: -${theme.gapTablet};
    width: calc(100% + ${theme.gapTablet} * 2);
    padding-left: ${theme.gapTablet};
  }
`;

const Headline = styled.h2`
  max-width: 7em;
  margin-top: 0;
  color: ${theme.colorDark};
  font-size: 1.375rem;
  line-height: 1.0909090909;
  letter-spacing: -0.06818181818em;

  @media ${bp.tablet} {
    position: relative;
    margin-top: -.15em;
    line-height: 1.0909090909;
    letter-spacing: -0.06818181818em;
  }
`;

const TextContainer = styled.div`
  max-width: 20em;
  margin-top: 1.5rem;
  color: ${theme.colorMedium};
  font-size: 1rem;
  line-height: 1.1875;
  letter-spacing: -0.0525em;
`

const variants = {
  visible: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 25,
    opacity: 0,
  },
}

function MediaAndText( props ) {

  const [ ref, inView ] = useInView( {
    threshold: 0.1,
    triggerOnce: true
  } );

  let media = <></>;
  let media_caption = <></>;
  if ( props.media ) {
  
    media_caption = <MediaCaption>{props.media.title}</MediaCaption>;

    const media_type = props.media.mimeType.indexOf("image") > -1 ? "image" : "video";
    if ( media_type === "image" ) {
      
      const image = props.media.responsiveImage;
      media = (
        <picture>
          <source srcSet={image.webpSrcSet} type="image/webp" />
          <source srcSet={image.srcSet} type="image/jpeg" /> 
          <img src={image.src} sizes={`(min-width: ${bpSizes.tablet}) 50vw, 100vw`} alt={image.title}></img>
        </picture>
      );

    }

  }

  const text_html = { __html: props.text };

  return (
    <Container
      ref={ref}
    >
      <motion.div
        initial="hidden"
        animate={inView ? 'visible' : 'hidden' }
        variants={variants}
        transition={{ ease: "easeInOut", duration: .75}}
      >
        <ColumnsContainer outmost>
          
          <MediaContainer>

            {media}
            {media_caption}

          </MediaContainer>

          <ContentContainer>

            <Headline>{props.headline}</Headline>
            <TextContainer dangerouslySetInnerHTML={text_html} />

          </ContentContainer>

        </ColumnsContainer>
      </motion.div>
    </Container>
  );
}

export default MediaAndText;