import React, { useState } from "react";
import { useQuery } from 'graphql-hooks'
import { Helmet } from 'react-helmet-async';
import { renderMetaTags } from "react-datocms";
import Analytics from 'react-router-ga';
import {
  Route,
} from "react-router-dom";
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

import GlobalStyle from '../../theme/GlobalStyle';

import Header from '../header/Header';
import Dev from '../dev/Dev';
import BackgroundAudio from '../backgroundAudio/BackgroundAudio';

import Page from '../page/Page';
import Destination from '../destination/Destination';

const SITE_QUERY = `query Site {
  _site {
    faviconMetaTags(variants: icon) {
      attributes
      content
      tag
    }
    globalSeo(locale: en) {
      facebookPageUrl
      fallbackSeo {
        description
        title
        twitterCard
      }
      siteName
      titleSuffix
      twitterAccount
    }
  }
  footer {
    aboutLink {
      slug
      title
    }
    accordionHeadline
    accordion {
      headline
      text
    }
    contact
    policyLinks {
      slug
      title
    }
  }
  footerMenu {
    menuItem {
      title
      url
      external
      openInNewWindow
    }
  }
  global {
    backgroundAudio {
      url
    }
  }
}`;


function App() {

  const [ heroMediaReady, setHeroMediaReady ] = useState(false);
  const [ heroTopMost, setHeroTopMost ] = useState(true);
  const [ mute, setMute ] = useState(false);
  const [ autoPlayEnabled, setAutoPlayEnabled ] = useState(false);
  const [ scrollY, setScrollY ] = useState(0);
  const [ nextPageType, setNextPageType ] = useState("");
  const [ headerVisible, setHeaderVisible ] = useState(true);

  const { loading, error, data } = useQuery(SITE_QUERY);
  if (loading) return <></>//"Loading...";
  if (error) return <></>//"Something Bad Happened";


  const favicon_meta = renderMetaTags(data._site.faviconMetaTags);
  const meta_description = data._site.globalSeo.fallbackSeo.description;
  const cookie_widget = (
    <script>{`
      !function(){var i,e,t,s=window.legal=window.legal||[];if(s.SNIPPET_VERSION="3.0.0",i="https://widgets.legalmonster.com/v1/legal.js",!s.__VERSION__)if(s.invoked)window.console&&console.info&&console.info("legal.js: The initialisation snippet is included more than once on this page, and does not need to be.");else{for(s.invoked=!0,s.methods=["cookieConsent","document","ensureConsent","handleWidget","signup","user"],s.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);return e.unshift(t),s.push(e),s}},e=0;e<s.methods.length;e++)t=s.methods[e],s[t]=s.factory(t);s.load=function(e,t){var n,o=document.createElement("script");o.setAttribute("data-legalmonster","sven"),o.type="text/javascript",o.async=!0,o.src=i,(n=document.getElementsByTagName("script")[0]).parentNode.insertBefore(o,n),s.__project=e,s.__loadOptions=t||{}},s.widget=function(e){s.__project||s.load(e.widgetPublicKey),s.handleWidget(e)}}}();

      legal.widget({
          type: "cookie",
          widgetPublicKey: "qmfmewBcr8qp3DG1gS6r8UZ3",
      });
      `}
    </script>
  );
  

  const autoPlayEnable = e => setAutoPlayEnabled( true );
  const muteClicked = e => setMute( !mute );
  const background_audio = <BackgroundAudio autoPlayEnable={autoPlayEnable} mute={mute} url={data.global.backgroundAudio.url} />;


  const destinationClicked = e => {

    const y = window.scrollY;
    setScrollY(y);
    return false;

  };

  const destinationClosed = e => {
    
    setTimeout(()=> {
      window.scrollTo( 0, scrollY );
      setHeaderVisible( true );
    }, 500); // 500 is the duration of the destination close animation

  }


  const pageExit = node => {
  
    if ( nextPageType === "destination" ) {

      node.style.position = "fixed";
      node.style.width = "100%";
      node.style.top = -1 * scrollY + "px";

    }

  }


  const transitionClasses = name => {
    
    return {
  
      appear: `${name} ${name}--appear`,
      appearActive: `${name} ${name}--appear-active`,
      appearDone: `${name} ${name}--appear-done`,
      enter: `${name} ${name}--enter`,
      enterActive: `${name} ${name}--enter-active`,
      enterDone: `${name} ${name}--enter-done`,
      exit: `${name} ${name}--exit`,
      exitActive: `${name} ${name}--exit-active`,
      exitDone: `${name} ${name}--exit-done`,

     }

  }

  return (
    
    <div>
      
        <Helmet>
          {favicon_meta}
          <meta name="description" content={meta_description} />
          {cookie_widget}
        </Helmet>

        <GlobalStyle />

        <Analytics id={process.env.REACT_APP_ANALYTICS_ID}>

          {background_audio}
          <Header visible={headerVisible} heroMediaReady={heroMediaReady} heroTopMost={heroTopMost} />

          <TransitionGroup component={null}>
            
            <Route key="routeHome" path="/" exact>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={1100}
                  classNames={ transitionClasses('c-page') }
                  unmountOnExit
                  onEnter={(node) => {
                    if ( match != null ) {
                      setNextPageType( 'page' );
                    }
                  } }
                  onExited={() => {
                    if (nextPageType === 'page') window.scrollTo(0,0);
                  }}
                  onExiting={node => {
                    pageExit( node );
                  }}
                >
                  <div className="c-page">
                    <Page
                      id="landing"
                      muteClicked={muteClicked}
                      autoPlayEnabled={autoPlayEnabled}
                      heroMediaReady={() => { setHeroMediaReady( true ) } }
                      heroTopMost={( val ) => { setHeroTopMost( val ) } }
                      destinationClicked={destinationClicked}
                      footer={data.footer}
                      footerMenu={data.footerMenu}
                    />
                  </div>
                </CSSTransition>
              )}
            </Route>

            <Route key={`routePage`} path="/:id" exact>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={1100}
                  classNames={ transitionClasses('c-page') }
                  unmountOnExit
                  onEnter={(node) => {
                    if ( match != null ) {
                      setNextPageType( 'page' );
                    }
                  } }
                  onExited={() => {
                    if (nextPageType === 'page') window.scrollTo(0,0);
                  }}
                  onExiting={node => {
                    pageExit( node );
                  }}
                >
                  <div className="c-page">
                    <Page
                      id="home"
                      muteClicked={muteClicked}
                      autoPlayEnabled={autoPlayEnabled}
                      heroMediaReady={() => { setHeroMediaReady( true ) } }
                      heroTopMost={( val ) => { setHeroTopMost( val ) } }
                      destinationClicked={destinationClicked}
                      footer={data.footer}
                      footerMenu={data.footerMenu}
                    />
                  </div>
                </CSSTransition>
              )}
            </Route>

            <Route key={`routeDestination`} path="/destination/:id" exact>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={1100}
                  classNames={ transitionClasses('c-destination') }
                  unmountOnExit
                  onEnter={() => {
                    if ( match != null ) {
                      setHeaderVisible( false );
                      setNextPageType( 'destination' );
                    }
                  } }
                  onExit={destinationClosed}
                >
                  <div className="c-destination">
                    <Destination {...match} />
                  </div>
                </CSSTransition>
              )}
            </Route>

          </TransitionGroup>

        </Analytics>

        <Dev />

    </div>
    
  );
}

export default App;

