import React, { useState } from 'react';
import styled from 'styled-components';
import { useInView } from "react-intersection-observer";
import { motion, useViewportScroll } from "framer-motion";
import { theme, bp, bpSizes } from '../../theme/GlobalStyle';
// import Logo from '../../theme/Logo';
import BookingSearch from './BookingSearch';


const containerVariants = {
  open: {
    top: "0rem",
    paddingTop: "3.5rem"
  },
  closed: {
    top: "-13.3rem",
    paddingTop: "0rem"
  }
};


const Container = styled(motion.div)`
  position: sticky;
  z-index: 10000;
  width: 100%;
  background-color: ${theme.bgColorLight};

  @media ${bp.tablet} {
    top: -13.3rem;
    padding-top: 0 !important;
  }

  @media ${bp.laptop} {
    top: 0 !important;
    height: auto !important;
    padding-top: 0 !important;
  }
`;


const Inner = styled(motion.div)`
  position: relative;
`;

const hitFieldVariants = {
  open: { opacity: .9, pointerEvents: 'auto' },
  closed: { opacity: 0, pointerEvents: 'none' }
};

const HitField = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9900;
  background-color: ${theme.bgColor};
  cursor: pointer;

  @media ${bp.laptop} {
    display: none;
  }
`;


const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
}


function BookingSearchWrapper() {

  const [ open, setOpen ] = useState(false);
  const [ initialHidden, setInitialHidden ] = useState(true);
  const { scrollY } = useViewportScroll()
  scrollY.onChange( x => {
    setInitialHidden( x === 0 ); // If scrolled to top
  } );

  const [ ref, inView ] = useInView( {
    rootMargin: "0px 0px 100px 0px",
    triggerOnce: true
  } );

  return (
    <>

      <Container
        ref={ref}
        onClick={() => { if ( window.innerWidth < parseFloat( bpSizes.laptop ) ) setOpen(true); } }
        animate={open ? "open" : "closed"}
        variants={containerVariants}
        transition={{ ease: "easeInOut", duration: .25}}
      >
        <Inner
          initial={initialHidden ? "hidden" : "visible" }
          animate={inView ? 'visible' : 'hidden' }
          variants={variants}
          transition={{ ease: "easeInOut", duration: .75, delay: 1 }}
        >
          <BookingSearch />
        </Inner>
      </Container>
      
      <HitField
        onClick={() => setOpen(false)}
        animate={open ? "open" : "closed"}
        variants={hitFieldVariants}
        transition={{ ease: "easeInOut", duration: .25}}
      />

    </>
  );

}

export default BookingSearchWrapper;