import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme, bp } from '../../theme/GlobalStyle';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Link = styled.a`
  display: block;
  padding: .75rem 1.685rem;
  border-radius: 2rem;
  background-color: ${theme.colorDark};
  text-decoration: none;
  color: ${theme.colorWhite};
  font-size: 0.9375rem;
  letter-spacing: -0.03333333333em;
  font-family: ${theme.ffRegular};

  @media ${bp.tablet} {
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;
    font-size: 1.25rem;
    letter-spacing: -0.05em;
  }

  &:hover {
    color: ${theme.colorWhite60};
  }
`;

const Price = styled.p`
  display: flex;
  margin: 0 0 0 1rem;
  color: ${theme.colorDark};
  white-space: nowrap;
  font-size: 1.75rem;
  line-height: 1;
  letter-spacing: -0.03571428571em;

  @media ${bp.tablet} {
    margin-left: .75rem;
    font-size: 2rem;
    letter-spacing: -0.068125em;
  }

  span {
    margin-top: .2rem;
    margin-left: 1em;
    color: ${theme.color};
    font-size: .8125rem;
    letter-spacing: -0.06230769231em;
  }
`;


function BookingButton( props ) {

  // const [ currency, setCurrency ] = useState("");
  const [ price, setPrice ] = useState("");
  const { id } = props;

  useEffect( () => {

    const controller = new AbortController();
    const { signal } = controller;

    fetch(`https://api.lodgify.com/v2/properties/${id}`, {
      signal: signal, 
      headers: new Headers( {

        "X-ApiKey": `${process.env.REACT_APP_LODGIFY_TOKEN}`,

      } ),

    } )
    .then( res => res.json() )
    .then( ( result ) => {
      
      // setCurrency( result.currency_code );
      setPrice( result.min_price );

    },
    ( error ) => { console.log( error ) } );

    return function cleanup() {
      controller.abort();
    };

  } );

  return (
    <Container>
      <Link
        href={`https://checkout.lodgify.com/${process.env.REACT_APP_LODGIFY_PROFILE}/en/?currency=EUR#/${id}`}
        target="_blank"
        rel="noreferrer"
      >Book</Link>
      <Price>{price}€ <span>pr. night</span></Price>
    </Container>
  );

}

export default BookingButton;