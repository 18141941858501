import styled from 'styled-components';
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { ColumnsContainer } from '../../theme/Components';
import { theme, bp } from '../../theme/GlobalStyle';

const Container = styled.div`
  padding-bottom: 2.5rem;
  background-color: ${theme.bgColorLight};

  @media ${bp.tablet} {
    padding-bottom: 6.5rem;
  }
`;

const Headline = styled(motion.h2)`
  grid-column-start: 1;
  grid-column-end: ${theme.columns + 1};
  margin-top: 2rem;
  margin-bottom: 6.0625rem;
  color: ${theme.colorDark};
  font-size: 1.75rem;
  line-height: 1.1071428571;
  letter-spacing: -0.0525em;

  @media ${bp.tablet} {
    grid-column-start: 4;
    grid-column-end: span 4;
    margin-top: 1rem;
    margin-bottom: 8.375rem;
    font-size: 2.375rem;
    line-height: 1.0526315789;
    letter-spacing: -0.05263157895em;
  }
`;

const Partners = styled(motion.div)``;

const Partner = styled.div`
  grid-column-end: span ${theme.columns};
  margin-bottom: 4.375rem;
  padding-bottom: 2.5rem;
  padding-left: ${theme.gap};
  border-left: 1px solid ${theme.borderColor};

  @media ${bp.tablet} {
    position: relative;
    left: -${theme.gapTablet};
    margin-bottom: 1rem;
    padding-bottom: 5rem;
    grid-column-end: span 3;
    padding-left: ${theme.gapTablet};
  }

  &:first-child {
    @media ${bp.tablet} {
      grid-column-start: 4;
    }
  }
`;

const PartnerTitle = styled.p`
  min-height: 2.2em;
  margin-top: 0;
  color: ${theme.colorDark};
  font-size: 1.375rem;
  line-height: 1.0909090909;
  letter-spacing: -0.06818181818em;

  @media ${bp.tablet} {
  
  }
`;

const PartnerText = styled.div`
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  font-size: 1rem;
  line-height: 1.1875;
  letter-spacing: -0.0625em;
`;

const PartnerLogo = styled.picture`
  display: block;
  width: 40%;
`;


const variants = {
  visible: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 25,
    opacity: 0,
  },
}


const HeadlineComponent = function( props ) {
  
  const [ ref, inView ] = useInView( {
    threshold: 0.1,
    triggerOnce: true
  } );

 return (
  <Headline
    plainLayout={props.plainLayout}
    ref={ref}
    initial="hidden"
    animate={inView ? 'visible' : 'hidden' }
    variants={variants}
    transition={{ ease: "easeInOut", duration: .75}}
  >{props.headline}</Headline>
 );

};


const PartnersComponent = function( props ) {
  
  const [ ref, inView ] = useInView( {
    threshold: 0.1,
    triggerOnce: true
  } );

 return (
  <Partners
    plainLayout={props.plainLayout}
    ref={ref}
    initial="hidden"
    animate={inView ? 'visible' : 'hidden' }
    variants={variants}
    transition={{ ease: "easeInOut", duration: .75}}
  >{props.content}</Partners>
 );

};


function PartnerList( props ) {

  const partners = props.partners.map( ( partner, i ) => {
    
    let logo = <></>;
    if ( partner.logo ) {

      const image_data = partner.logo.responsiveImage;
      logo = (
        <PartnerLogo>
          <source srcSet={image_data.webpSrcSet} type="image/webp" />
          <source srcSet={image_data.srcSet} type="image/jpeg" /> 
          <img src={image_data.src} alt={image_data.title}></img>
        </PartnerLogo>
      );
    }
    
    return (
      <Partner key={`partner_${i}`}>
        <PartnerTitle>{partner.title}</PartnerTitle>
        <PartnerText>{partner.text}</PartnerText>
        {logo}
      </Partner>
    );

  } );

  const partners_content = (
    <ColumnsContainer outmost>
      {partners}
    </ColumnsContainer>
  );

  return (
    <Container>
      <ColumnsContainer outmost>
        <HeadlineComponent headline={props.headline} />
      </ColumnsContainer>

      <PartnersComponent content={partners_content} />
    </Container>
  );
}

export default PartnerList;