import { useEffect } from 'react';
import styled from 'styled-components';
import { ColumnsContainer } from '../../theme/Components';
import { theme, bp } from '../../theme/GlobalStyle';
import { useQuery } from 'graphql-hooks'
import { Helmet } from 'react-helmet-async';
import { renderMetaTags } from "react-datocms";
import { useParams } from "react-router-dom";

import Footer from '../footer/Footer';
import BookingSearchWrapper from '../bookingSearch/BookingSearchWrapper';
import Hero from '../hero/Hero';
import Text from '../text/Text';
import MediaAndText from '../mediaAndText/MediaAndText';
import DestinationList from '../destinationList/DestinationList';
import PartnerList from '../partnerList/PartnerList';

const PAGE_QUERY = `query Page($slug: String) {
  page(filter: {slug: { eq: $slug} }) {
    title
    showPageTitle
    seo: _seoMetaTags {
      attributes
      content
      tag
    }
    modules {
      ... on BookingSearchRecord {
        _modelApiKey
      }
      ... on DestinationListRecord {
        _modelApiKey
        headline
        destinations {
          id
          slug
          thumbnail {
            mimeType
            responsiveImage {
              alt
              src
              srcSet
              webpSrcSet
              title
            }
          }
          gallery {
            mimeType
            responsiveImage {
              alt
              src
              srcSet
              webpSrcSet
              title
            }
          }
          tagLine
          description
          title
        }
      }
      ... on HeroRecord {
        _modelApiKey
        extendedLayout
        useExternalVideo
        videoSource
        media {
          format
          mimeType
          responsiveImage {
            alt
            src
            srcSet
            webpSrcSet
          }
        }
        headline
        secondaryText
      }
      ... on MediaAndTextRecord {
        _modelApiKey
        media {
          alt
          title
          format
          mimeType
          responsiveImage {
            alt
            src
            srcSet
            webpSrcSet
          }
        }
        headline
        text
      }
      ... on PartnerListRecord {
        _modelApiKey
        headline
        partners {
          logo {
            responsiveImage {
              alt
              src
              srcSet
              webpSrcSet
            }
          }
          text
          title
        }
      }
      ... on TextRecord {
        _modelApiKey
        plainLayout
        headline
        text
        secondaryText
      }
    }
  }
}`;

const PageTitleContainer = styled.div`
  padding-bottom: 6.875rem;
  background-color: ${theme.bgColorLight};

  @media ${bp.tablet} {
    position: relative;
    margin-top: -1.25rem;
    padding-top: 1.25rem;
    padding-bottom: 9.375rem;
  }
`;

const PageTitle = styled.h1`
  grid-column-end: span 6;
  position: relative;
  display: block;
  top: 2.75rem;
  color: ${theme.colorDark};
  font-size: 2.375rem;
  letter-spacing: -0.05263157895em;

  @media ${bp.tablet} {
    grid-column-start: 4;
    grid-column-end: span 4;
    top: -.8rem;
  }
`;


function Page( props ) {
  
  let { id } = useParams();
  
  if ( !id && props.id ) {
    id = props.id
  }
  
  const { loading, error, data } = useQuery(PAGE_QUERY, {
    variables: {
      slug: id
    }
  });

  useEffect(() => {
    if ( data && data.page && data.page.modules ) {
      props.heroTopMost( data.page.modules[ 0 ]._modelApiKey === "hero" );
    }
  }, [ props, data ]);
  
  if ( !id ) return <></>;
  if (loading) return <></>;//'Loading...'
  if (error) return <></>;//'Something Bad Happened'

  const meta = renderMetaTags(data.page.seo);

  let page_title = <></>;
  if ( data.page.showPageTitle ) {
    page_title = (
      <PageTitleContainer>
        <ColumnsContainer outmost>
          <PageTitle>{data.page.title}</PageTitle>
        </ColumnsContainer>
      </PageTitleContainer>
    );
  }

  const modules = data.page.modules.map( ( module, i ) => {
    
    const key = `${module._modelApiKey}_${i}`;

    if ( module._modelApiKey === "booking_search" ) {

      return <BookingSearchWrapper key={key} />;

    } else if ( module._modelApiKey === "hero" ) {
      
      return <Hero
        key={key}
        autoPlayEnabled={props.autoPlayEnabled}
        extendedLayout={module.extendedLayout}
        useExternalVideo={module.useExternalVideo}
        videoSource={module.videoSource}
        headline={module.headline}
        secondaryText={module.secondaryText}
        media={module.media}
        topMost={i === 0}
        muteClicked={props.muteClicked}
        heroMediaReady={props.heroMediaReady}
      />;

    } else if ( module._modelApiKey === "text" ) {

      return <Text
        key={key}
        plainLayout={module.plainLayout}
        headline={module.headline}
        text={module.text}
        secondaryText={module.secondaryText} />;

    } else if ( module._modelApiKey === "media_and_text" ) {

      return <MediaAndText
        key={key}
        headline={module.headline}
        text={module.text}
        media={module.media} />;

    } else if ( module._modelApiKey === "destination_list" ) {

      return <DestinationList
        key={key}
        destinationClicked={props.destinationClicked}
        headline={module.headline}
        destinations={module.destinations} />;

    } else if ( module._modelApiKey === "partner_list" ) {

      return <PartnerList
        key={key}
        headline={module.headline}
        partners={module.partners} />;

    } else {

      console.log('Missing module: ', module );

    }

    return <div key={key}></div>;

  } );

  // const handleExitedScroll = e => {
  //   console.log('page handleExitedScroll', e);
  // }

  // const duration = 1200;

  // const defaultStyle = {
  //   transition: `opacity ${duration}ms ease-in-out`,
  //   opacity: 0,
  // }

  // const transitionStyles = {
  //   entering: { opacity: 1 },
  //   entered:  { opacity: 1 },
  //   exiting:  { opacity: .5 },
  //   exited:  { opacity: .5 },
  // };

  return (
    
      <>
      <Helmet>{meta}</Helmet>      
    
      {page_title}
      {modules}

      <Footer footer={props.footer} footerMenu={props.footerMenu} />
      </>
  );
}



export default Page;