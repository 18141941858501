import styled from 'styled-components';
import { theme, bp } from './GlobalStyle';

export const ColumnsContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(${theme.columns}, 1fr);
  column-gap: ${theme.gap};
  row-gap: ${props => props.noRowGap ? 0 : theme.gap };
  max-width: ${props => props.outmost ? theme.maxWidth : 'auto' };
  height: ${props => props.fullHeight ? '100%' : 'auto' };
  margin-right: auto;
  margin-left: auto;
  padding-right: ${props => props.outmost ? theme.gutter : '0' };
  padding-left: ${props => props.outmost ? theme.gutter : '0' };

  @media ${bp.tablet} {
    grid-gap: ${theme.gapTablet};
  }
`;