import { useState, useEffect } from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { motion, useViewportScroll } from "framer-motion"
import { bp, theme } from '../../theme/GlobalStyle';
import { ColumnsContainer } from '../../theme/Components';
import Logo from '../../theme/Logo';

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11000;
  padding-top: 1.35rem;
  pointer-events: none;

  @media ${bp.tablet} {
    padding-top: 1.2rem;
  }

  a {
    transition: color 200ms ease-in-out;
    color: ${props => props.whiteLogo ? theme.colorWhite : theme.colorDark };
  }
`;

const LogoContainer = styled.div`
  grid-column-end: span 4;
  pointer-events: auto;

  @media ${bp.tablet} {
    grid-column-end: span 2;
  }

  svg {
    width: 100%;
    max-width: 4.1875rem;

    @media ${bp.tablet} {
      max-width: 5.6875rem;
    }
  }

  path {
    fill: currentColor;
  }
`;

const visibleVariants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 0 },
}

function Header( props ) {

  const [whiteLogo, setWhiteLogo] = useState( true );

  useEffect( () => {
    if ( !props.heroTopMost ) setWhiteLogo( false );
  }, [ props.heroTopMost ] );

  const { scrollY } = useViewportScroll();

  if ( props.heroTopMost ) {

    scrollY.onChange( x => {
      
      setWhiteLogo( x < window.innerHeight * .9 - 32 && props.visible ); // If scrolled down 92% of viewport - 32px and not transitioning to destination
      
    } );

  } else {

    // setWhiteLogo( false );

  }

  return (
    <Container
      whiteLogo={whiteLogo}
      initial="hidden"
      animate={!props.heroTopMost || props.heroMediaReady ? 'visible' : 'hidden' }
      // animate={ props.heroMediaReady ? 'visible' : 'hidden' }
      variants={visibleVariants}
      transition={{ ease: "easeInOut", delay: 1, duration: 1}}
    >
      <ColumnsContainer outmost>
        <LogoContainer>
          <Link to="/">
            <Logo />
          </Link>
        </LogoContainer>
      </ColumnsContainer>
    </Container>
  );  

}

export default Header;