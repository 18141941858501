import styled from 'styled-components';
import { Link } from "react-router-dom";
import { bp, theme } from '../../theme/GlobalStyle';
import { ColumnsContainer } from '../../theme/Components';
import Logo from '../../theme/Logo';
import Accordion from '../accordion/Accordion';
import MailchimpForm from '../mailchimpForm/MailchimpForm';

const Container = styled.footer`
  padding-top: 1.35rem;
  padding-bottom: 2rem;
  color: ${theme.colorDark};
  font-size: 1.375rem;
  line-height: 1.0909090909;
  letter-spacing: -0.06045454545em;

  @media ${bp.tablet} {
    padding-top: 1.3rem;
    padding-bottom: 1.375rem;
  }

  a,
  Link {
    color: ${theme.colorDark};
  }

  & > div {
    row-gap: 0;
  }
`;

const CopyrightContainer = styled.div`
  order: 999;
  grid-column-start: 1;
  grid-column-end: span ${theme.columns};
  display: flex;
  align-items: flex-end;
  margin-top: 3.5rem;
  color: ${theme.colorMedium};
  font-size: 0.75rem;
  line-height: 1.25;
  letter-spacing: -0.04416666667em;

  @media ${bp.tablet} {
    order: 0;
    grid-column-end: span 6;
    margin-top: 0;
    font-size: 0.875rem;
    line-height: 1.0714285714;
    letter-spacing: -0.04357142857em;
  }

  p {
    margin: 0;
  }
`;

const LogoContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: span ${theme.columns};
  margin-bottom: 7rem;

  @media ${bp.tablet} {
    position: absolute;
  }

  svg {
    width: 100%;
    max-width: 4.1875rem;

    @media ${bp.tablet} {
      max-width: 5.6875rem;
    }
  }
`;

const AccordionContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: span ${theme.columns};
  border-left: 1px solid ${theme.borderColor};

  @media ${bp.tablet} {
    grid-column-start: 7;
    grid-column-end: span 3;
  }
`;

const AccordionHeadline = styled.p`
  max-width: 10em;
  margin-top: 0;
  margin-bottom: 4.625rem;
  padding-left: ${theme.gap};
  font-size: 1.375rem;
  line-height: 1.0909090909;
  letter-spacing: -0.06045454545em;

  @media ${bp.tablet} {
    margin-bottom: 15.6125rem;
    padding-left: ${theme.gapTablet};
  }
`;

const ContentContainer = styled.div`
  grid-column-end: span ${theme.columns};
  display: flex;
  flex-direction: column;
  padding-top: 5.625rem;
  padding-left: ${theme.gap};
  border-left: 1px solid ${theme.borderColor};
  
  @media ${bp.tablet} {
    grid-column-end: span 3;
    padding-top: 0;
    padding-left: ${theme.gapTablet};
  }
`;

const Contact = styled.div`
  margin-top: 1.625rem;
  
  &,
  a {
    color: ${theme.colorMedium};
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  flex-direction: column;
  margin-top: 1.625rem;
`;

const PolicyLinks = styled.nav`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-top: 9rem;
  margin-bottom: 0;
  font-size: .75rem;
  line-height: 1.25;
  letter-spacing: -0.04333333333em;
  color: ${theme.colorMedium};

  @media ${bp.tablet} {
    display: block;
    margin-top: auto;
    font-size: 0.875rem;
    line-height: 1.0714285714;
    letter-spacing: -0.04066666667em;
  }

  a:not(:last-child),
  Link:not(:last-child) {
    @media ${bp.tablet} {
      margin-right: 1em;
    }
  }
`;




function Footer( props ) {

  if ( props.footer ) {

    let about_link = <></>;
    if ( props.footer.aboutLink ) about_link = <Link to={`/${props.footer.aboutLink.slug}`}>{props.footer.aboutLink.title}</Link>

    let links = <></>;
    if (
      props.footerMenu &&
      props.footerMenu.menuItem &&
      props.footerMenu.menuItem.length
    ) {

      links = props.footerMenu.menuItem.map( ( link, i ) => {

        const target = link.openInNewWindow ? '_blank' : '';
        
        if ( link.external ) return <a key={`footerMenuItem_${i}`} href={link.url} target={target}>{link.title}</a>
        
        return <div key={`footerMenuItem_${i}`} />;

      } );

    }

    const contact_html = { __html: props.footer.contact };

    let policy_links = <></>;
    if (
      props.footer.policyLinks &&
      props.footer.policyLinks.length
    ) {

      policy_links = props.footer.policyLinks.map( ( link, i ) => {

        return <Link key={`footerPolicyLink_${i}`} to={link.slug}>{link.title}</Link>;

      } );

    }

    const date = new Date();
    const year = date.getFullYear();
    
    return (
    
      <Container>
        <ColumnsContainer outmost>
          <LogoContainer>
            <Link to="/">
              <Logo />
            </Link>
          </LogoContainer>
          
          <CopyrightContainer>
            <p>© {year} Havnaert</p>
          </CopyrightContainer>

          <AccordionContainer>

            <AccordionHeadline>{props.footer.accordionHeadline}</AccordionHeadline>
            <Accordion items={props.footer.accordion}></Accordion>
            
          </AccordionContainer>

          <ContentContainer>

            {about_link}

            <NavLinks>
              {links}
            </NavLinks>

            <Contact dangerouslySetInnerHTML={contact_html} />

            <MailchimpForm />

            <PolicyLinks>
              {policy_links}
            </PolicyLinks>

          </ContentContainer>

        </ColumnsContainer>
      </Container>
    
    );  

  }

  return <></>;

}

export default Footer;